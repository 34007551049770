@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';

.suggestionsWrapper {
  background: colors.$background;
  height: 100vh;
  width: 100vw;
  left: 0;
  position: absolute;
  padding: 0px 10px;
  margin-top: 25px;
  margin-left: -10px;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease, visibility 0.5s ease, transform 0.5s ease;
  transform-origin: top center;

  @media screen and (min-width: vars.$small) {
    margin-left: -15px;
    padding: 0px 15px;
  }

  @media screen and (min-width: vars.$large) {
    width: 100%;
    margin-left: 0;
    padding: 0px;
  }

  @media screen and (min-width: vars.$extra-large) {
    background: none;
    height: initial;
  }
}

.suggestionsWrapperMP {
  background: none;
  height: calc(100vh - 71px);
  width: 100vw;
  left: 0;
  top: 71px;
  position: absolute;
  padding: 0px;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease, visibility 0.5s ease, transform 0.5s ease;
  transform-origin: top center;
  left: 50%;
  transform: translateX(-50%);
  overflow: auto;

  &.suggestionsWrapperEmpty {
    @media screen and (min-width: vars.$extra-large) {
      display: none;
    }
  }

  @media screen and (min-width: vars.$medium) {
    width: 100%;
  }

  @media screen and (min-width: vars.$large) {
    // width: 100%;
    margin-left: 0;
    // padding: 0px;
  }

  @media screen and (min-width: vars.$extra-large) {
    height: calc(100vh - 76px);
    top: 76px;
  }
}

.backgroundLayer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 190px;
  left: 0;
  z-index: 0;
  background: rgba(21, 21, 21, 0.75);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease, visibility 0.5s ease, transform 0.5s ease;
  transform-origin: top center;
  left: 50%;
  transform: translateX(-50%);

  @media screen and (min-width: vars.$medium) {
    top: 205px;
  }

  @media screen and (min-width: vars.$extra-large) {
    top: 150px;
  }
}

.visible {
  opacity: 1;
  visibility: visible;
}

.suggestionBox {
  background-color: colors.$white;
  border-radius: 4px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
  left: 0;
  margin-top: -5px;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  right: 0;
  width: 100%;

  display: flex;
  padding: 0 16px 16px 16px;
  flex-direction: column;
  position: relative;
}

.productCard {
  height: auto !important;
  width: 100%;

  @media screen and (min-width: vars.$small) {
    width: calc(100% / 3 - 16px);
  }
}

.suggestionProducts {
  gap: 16px;
  padding-top: 32px;
  display: flex;
  flex-wrap: wrap;

  a:nth-child(n + 5) {
    display: none;
  }
  @media screen and (min-width: vars.$small) {
    a:nth-child(n + 5) {
      display: initial;
    }
  }
}

@mixin suggestionBOX {
  background-color: colors.$white;
  left: 0;
  right: 0;
  width: 100%;
}

.suggestionBoxMP {
  display: grid;
  grid-auto-flow: row;
  background-color: white;
  overflow-y: auto;
  position: relative;
  padding: 24px 16px 16px 16px;
  border-radius: 4px;

  @media screen and (min-width: vars.$extra-large) {
    column-gap: 32px;
    padding: 32px 32px 24px 32px;
  }

  @media screen and (min-width: vars.$extra-extra-large) {
    grid-auto-flow: column;
    grid-template-columns: 1fr 5fr;
    gap: 32px;
  }
}

.suggestionBoxMPNoProducts {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: none;
  background-color: white;
  overflow-y: auto;
  position: relative;
  padding: 24px 16px 16px 16px;
  border-radius: 4px;

  @media screen and (min-width: vars.$extra-large) {
    padding: 32px 32px 24px 32px;
  }
}

.actions {
  display: grid;
  gap: 16px;
  grid-auto-flow: row;
  grid-template-columns: 100%;
  width: 100%;
  grid-column: 100%;
  justify-content: end;

  @media screen and (min-width: vars.$small) {
    grid-auto-flow: initial;
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (min-width: vars.$medium) {
    grid-column: span 3;
    grid-template-columns: max-content max-content;
  }
  @media screen and (min-width: vars.$extra-large) {
    grid-column: span 3;
  }
  @media screen and (min-width: vars.$extra-extra-large) {
    grid-column: span 5;
  }
}

.closeSuggestionsBtn {
  padding: 0 16px;
  gap: 16px;

  @media screen and (min-width: vars.$extra-large) {
    display: none;
  }
}

.suggestionCategory {
  width: 100%;
  column-gap: 16px;
  display: flex;
  flex-direction: column;
}

.suggestionCategoryMP {
  width: 100%;
  display: grid;
  column-gap: 16px;
  grid-auto-flow: row;

  @media screen and (min-width: vars.$medium) {
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (min-width: vars.$extra-large) {
    grid-auto-flow: row;
    grid-template-columns: auto;
    grid-auto-rows: max-content;
  }
  @media screen and (min-width: vars.$extra-extra-large) {
    width: 275px;
  }
}

.suggestionCategoryMPNoProducts {
  @media screen and (min-width: vars.$extra-extra-large) {
    width: 100%;
  }
}

// .suggestionArea {
//   grid-area: suggestion;
// }

// .categoryArea {
//   grid-area: category;
// }

// .brandArea {
//   grid-area: brand;
// }

.query {
  color: colors.$default-text;
  margin-right: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
}

.suggestionTypeWrapper {
  background-color: var(--background);
}

.suggestionBrandTypeWrapper {
  background-color: var(--background);
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 16px;

  .suggestionType {
    border-radius: 4px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    background-color: var(--light-blue);
    min-height: 24px;
    color: white;

    a {
      color: colors.$white;
    }

    span {
      color: colors.$white;
      font-size: 16px;
      b {
        font-weight: 400;
      }
    }

    svg {
      cursor: pointer;
    }
  }

  .suggestionType.selected {
    color: var(--light-blue);
    background-color: white;

    span {
      color: var(--light-blue);
    }
  }
}

.suggestionType {
  position: relative;
  color: colors.$default-text;
  padding: 8px 16px;
  display: grid;
  grid-template-columns: auto min-content;

  &:hover,
  &:focus {
    background-color: colors.$primary-color;
    color: colors.$white;

    a {
      color: colors.$white;
    }

    .query, .in {
      color: colors.$white;
    }
  }
}

.in {
  color: colors.$primary-color;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: right;
  overflow: hidden;
}

.link {
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: inherit;
  display: flex;
  font-family: 'TT Prosto Sans Condensed', sans-serif;
  text-decoration: none;
  width: 100%;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  gap: 8px;
}

.clockIcon {
  flex-shrink: 0;
}

.suggestionName {
  font-stretch: condensed;
  line-height: 1.125rem;
  color: #9e9e9e;
  font-size: 12px;
  text-align: left;
  margin-bottom: 8px;
  margin-top: 16px;
}

.selected {
  background-color: colors.$primary-color;
  color: colors.$white;

  a,
  span {
    color: colors.$white;
  }
}

.showAllBtn {
  font-weight: 400;
  display: grid;
  padding: 0 16px;
  gap: 4px;
  grid-auto-flow: column;
  align-items: center;
  grid-auto-columns: max-content;

  span,
  svg {
    cursor: pointer;
  }
}

.searchIconAction {
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  align-items: center;
  gap: 16px;
  position: relative;
  color: var(--default-text);
  width: 100%;

  & svg {
    fill: none;
    width: 38px;

    &:not(:last-child) {
      cursor: pointer;
    }
  }

  @media screen and (min-width: vars.$extra-large) {
    display: none;
  }
}

.actionWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  cursor: pointer;
  gap: 8px;
  position: relative;

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.fileInputLabel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0; /* Make the label invisible */
  z-index: 1; /* Position label on top of the icon */
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchIconActionError {
  margin-top: 46px;
}

.errorArea {
  background-color: colors.$error;
  text-align: center;
  position: absolute;
  top: 0px;
  width: 100%;
  left: 0px;
  color: colors.$white;
  font-size: 14px;
  padding: 0 8px;
}

.shoppingPuggy {
  display: none;
  grid-template-columns: auto auto;
  align-items: center;

  svg {
    fill: none;
  }
  p {
    font-stretch: condensed;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }

  @media screen and (min-width: vars.$extra-large) {
    display: grid;
  }
}

.noProducts {
  display: grid;
  grid-auto-flow: column;
  margin-top: 16px;
  color: colors.$default-text;

  .actions {
    grid-template-columns: auto;
    align-items: end;
  }

  .closeSuggestionsBtn {
    height: 48px;
    margin-bottom: 16px;
  }
}

.removeHistoryItem {
  cursor: pointer;
}

.imageSearch {
  span {
    display: initial;
  }
}
